exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anambra-under-obiano-js": () => import("./../../../src/pages/Anambra_under_obiano.js" /* webpackChunkName: "component---src-pages-anambra-under-obiano-js" */),
  "component---src-pages-articlepost-wmo-js": () => import("./../../../src/pages/Articlepost_wmo.js" /* webpackChunkName: "component---src-pages-articlepost-wmo-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/Articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/Biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-blogpost-js": () => import("./../../../src/pages/Blogpost.js" /* webpackChunkName: "component---src-pages-blogpost-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/Contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-desktop-js": () => import("./../../../src/pages/Desktop.js" /* webpackChunkName: "component---src-pages-desktop-js" */),
  "component---src-pages-dr-ogechukwu-winnie-obiano-js": () => import("./../../../src/pages/Dr_Ogechukwu_Winnie_Obiano.js" /* webpackChunkName: "component---src-pages-dr-ogechukwu-winnie-obiano-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notable-js": () => import("./../../../src/pages/Notable.js" /* webpackChunkName: "component---src-pages-notable-js" */),
  "component---src-pages-roadmap-to-success-js": () => import("./../../../src/pages/Roadmap_to_success.js" /* webpackChunkName: "component---src-pages-roadmap-to-success-js" */),
  "component---src-pages-tribute-to-dr-chinwoke-mbadinuju-js": () => import("./../../../src/pages/Tribute_to_Dr_Chinwoke_Mbadinuju.js" /* webpackChunkName: "component---src-pages-tribute-to-dr-chinwoke-mbadinuju-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/Videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

